import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { interval, map, Observable, of, retry, startWith, switchMap, take, timer } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QueriesService {

  private _baseUrl = `${environment.baseUrl}`;
  private _stepsUrl = `${environment.stepsUrl}`;
  private _stepsExecUrl = `${environment.stepsExecUrl}`;

  constructor(private http: HttpClient) { }

  getToken() {
    return this.http.get<any>(`${this._baseUrl}/token`)
  }

  enrollPatient(data: any) {
    return this.http.post<any>(`${this._baseUrl}/enroll`, JSON.stringify(data));
  }

  exchangeCode(code: string) {
    return this.http.post<any>(`${this._baseUrl}/exchange-code`, JSON.stringify({ code: code }), { headers: { 'Content-Type': 'application/json' } });
  }

  acceptTerms() {
    return this.http.post<any>(`${this._baseUrl}/accept-terms`, '', { headers: { 'Content-Type': 'application/json' } });
  }

  checkUserMfa() {
    return this.http.get<any>(`${this._baseUrl}/check-mfa`);
  }

  enableUserMfa(phone: string) {
    return this.http.post<any>(`${this._baseUrl}/enable-mfa`, JSON.stringify({ phone: phone }), { headers: { 'Content-Type': 'application/json' } });
  }

  getUploadFileUrl(file: File) {

    const data = {
      contentType: file.type,

    }
    return this.http.post(`${this._baseUrl}/upload-script-url`, data, { headers: { 'Content-Type': 'application/json' } });
  }

  uploadFileToPresignedUrl(presignedUrl: string, file: File) {

    return this.http.put(presignedUrl, file, {
      headers: { 'Content-Type': file.type },
      // Important: Don't send default headers or credentials
      withCredentials: false,
      // Observe the full response to check for any issues
      observe: 'response'
    });
  }

  uploadFile(data: any) {
    return this.http.post(`${this._baseUrl}/upload-script`, data, { headers: { 'Accept': 'application/json' } });
  }

  getPatient(groupId: string, cognitoId: any) {
    return this.http.get<any>(`${this._baseUrl}/get-patient?groupId=${groupId}&id=${cognitoId}`);
  }

  addPatient(data: any) {
    return this.http.post<any>(`${this._stepsUrl}/add-patient/`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  addPatientUser(data: any) {
    return this.http.post<any>(`${this._baseUrl}/patient-user`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  updatePatient(data: any, groupId:string, patientId: string) {
    return this.http.put<any>(`${this._baseUrl}/update-patient/${groupId}/${patientId}`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  addNewGroup(data: any) {
    return this.http.post<any>(`${this._baseUrl}/add-group`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }
  updateGroup(data: any) {
    return this.http.put<any>(`${this._baseUrl}/update-group`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  addNewPayer(data: any) {
    return this.http.post<any>(`${this._baseUrl}/add-payer`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  submitEnrollment(data: any) {
    return this.http.post<any>(`${this._baseUrl}/enroll-patient`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  placeOrder(data: any) {
    return this.http.post<any>(`${this._baseUrl}/place-order`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  getGroups() {
    return this.http.get<any>(`${this._baseUrl}/get-groups`);
  }

  getSprucePatients() {
    return this.http.get<any>(`${this._baseUrl}/get-spruce-patients`);
  }

  getAllPatients() {
    return this.http.get<any>(`${this._baseUrl}/get-all-patients`);
  }

  getPatientsForGroup(groupId: string) {
    return this.http.get<any>(`${this._baseUrl}/get-group-patients?id=${groupId}`);
  }

  getPayers() {
    return this.http.get<any>(`${this._baseUrl}/payers`);
  }

  getPayer(groupId: string, payerId: string) {
    return this.http.get<any>(`${this._baseUrl}/get-payer?id=${payerId}&groupId=${groupId}`);
  }
  updatePayer(payer: any) {
    return this.http.put<any>(`${this._baseUrl}/payer`, JSON.stringify(payer));
  }

  getProducts() {
    return this.http.get<any>(`${this._baseUrl}/products`);
  }

  getGroupPricing() {
    return this.http.get<any>(`${this._baseUrl}/product-pricing-for-groups`);
  }

  getGroup(groupId: string) {
    return this.http.get<any>(`${this._baseUrl}/get-group?id=${groupId}`);
  }

  getProductPricingForGroup(id: string) {
    return this.http.get<any>(`${this._baseUrl}/group-product-pricing?id=${id}`);
  }
  getPricingListById(id: string) {
    return this.http.get<any>(`${this._baseUrl}/pricing-list?id=${id}`);
  }
  saveGroupProductPricing(groupId: string, data: any[]) {
    return this.http.post<any>(`${this._baseUrl}/save-group-product-pricing?id=${groupId}`, JSON.stringify(data));
  }

  geCognitoUsers(role: string) {
    if (role.length > 0) {
      return this.http.get<any>(`${this._baseUrl}/users?role=${role}`);
    } else {
      return this.http.get<any>(`${this._baseUrl}/users`);
    }
  }

  geCognitoUsersMinusPatients() {
    return this.http.get<any>(`${this._baseUrl}/users-minus-patients`);
  }

  getCognitoUser(id: string) {
    return this.http.get<any>(`${this._baseUrl}/user?id=${id}`);
  }

  addCognitoUser(data: any) {
    return this.http.post<any>(`${this._baseUrl}/user`, JSON.stringify(data));
  }

  addAdminCognitoUser(data: any) {
    return this.http.post<any>(`${this._baseUrl}/add-user`, JSON.stringify(data));
  }

  updateCognitoUser(data: any) {
    return this.http.put<any>(`${this._baseUrl}/user`, JSON.stringify(data));
  }

  getEnrollments() {
    return this.http.get<any>(`${this._baseUrl}/enrollments`);
  }

  getLatestEnrollments(groupId: string) {
    return this.http.get<any>(`${this._baseUrl}/latest-enrollments?groupId=${groupId}`);
  }
  gePatientMostRecentOrder(groupId: string, patientId: string) {
    return this.http.get<any>(`${this._baseUrl}/patient-recent-order?groupId=${groupId}&patientId=${patientId}`);
  }
  submitEnrollmentOrder(data: any) {
    return this.http.post<any>(`${this._baseUrl}/submit-order`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  completeOrder(data: any) {
    return this.http.post<any>(`${this._baseUrl}/complete-order`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  initOAuth() {
    return this.http.get<any>(`${this._baseUrl}/auth`);
  }

  getPatientLastOrder(groupId: string, id: string) {
    return this.http.get<any>(`${this._baseUrl}/last-order?id=${id}&groupId=${groupId}`);
  }

  getEnrollment(groupId: string, id: string) {
    return this.http.get<any>(`${this._baseUrl}/enrollment?id=${id}&groupId=${groupId}`);
  }

  getPatientOrders(groupId: string, id: string) {
    return this.http.get<any>(`${this._baseUrl}/patient-orders?id=${id}&groupId=${groupId}`);
  }

  getPatientOrder(id: string) {
    return this.http.get<any>(`${this._baseUrl}/patient-order?id=${id}`);
  }

  getOrders() {
    return this.http.get<any>(`${this._baseUrl}/orders`);
  }
  getActiveOrders(groupId: string) {
    return this.http.get<any>(`${this._baseUrl}/active-orders?groupId=${groupId}`);
  }

  getListOfDartCareOrders(dartOrderId: string) {
    return this.http.get<any>(`${this._baseUrl}/dart-orders?id=${dartOrderId}`);
  }

  getCompany(id: string) {
    return this.http.get<any>(`${this._baseUrl}/company?id=${id}`);
  }
  getCompanies() {
    return this.http.get<any>(`${this._baseUrl}/companies`);
  }

  getCompaniesForGroup(id: string) {
    return this.http.get<any>(`${this._baseUrl}/group-companies?id=${id}`);
  }

  getCompaniesWithNoGroup() {
    return this.http.get<any>(`${this._baseUrl}/companies-no-group`);
  }

  addCompany(data: any) {
    return this.http.post<any>(`${this._baseUrl}/companies`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  getPatientsWithOrders(data: any) {
    return this.http.post<any>(`${this._baseUrl}/patients-with-orders`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }
  getPatientsWithEnrollments(data: any) {
    return this.http.post<any>(`${this._baseUrl}/patients-with-enrollments`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }
  submitNewOrder(data: any, isReorder: boolean, isenrollment: boolean) {
    return this.http.post<any>(`${this._baseUrl}/new-order?reorder=${isReorder}&isEnrollment=${isenrollment}`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }
  invitePatients(data: any) {
    return this.http.post<any>(`${this._stepsUrl}/register-patient`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }
  getTotalNumberOfOrders() {
    return this.http.get<any>(`${this._baseUrl}/total-order-count`);
  }

  getSystemOrders(pageSize: number, lastEvaluatedDBKey: any) {
    return this.http.post<any>(`${this._baseUrl}/orders?pageSize=${pageSize}`, JSON.stringify(lastEvaluatedDBKey), { headers: { 'Content-Type': 'application/json' } });
  }

  getIntuitAccessToken() {
    return this.http.get<any>(`${this._baseUrl}/intuit-token`);
  }

  addPayerToQuickBooks(data: any) {
    return this.http.post<any>(`${this._stepsUrl}/add-customer`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  getRegistrantByEmail(email: string) {
    return this.http.get<any>(`${this._baseUrl}/registration?email=${email}`);
  }

  getRegistrants() {
    return this.http.get<any>(`${this._baseUrl}/registrations`);
  }
  getUniquePriceListNames() {
    return this.http.get<any>(`${this._baseUrl}/price-lists-distinct`);
  }
  checkEmailExistsInCognito(email: string) {
    return this.http.get<any>(`${this._baseUrl}/email-exists?email=${email}`);
  }

  updateAsRegistered(email: string) {
    return this.http.post<any>(`${this._baseUrl}/updated-registered?email=${email}`, {});
  }

  searchPatients(groupId: string, filter: string) {
    return this.http.get<any>(`${this._baseUrl}/search-patients?groupId=${groupId}&filter=${filter}`);
  }

  submitPriceRequest(data: any) {
    return this.http.post<any>(`${this._baseUrl}/pricing-request`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  updateTrackingNumber(data: any) {
    return this.http.put<any>(`${this._baseUrl}/update-tracking-number`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  updateOrderStatus(data: any) {
    return this.http.put<any>(`${this._baseUrl}/update-order-status`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }
  updateInvoiceId(data: any) {
    return this.http.put<any>(`${this._baseUrl}/update-invoice-id`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }
  getGuide(filter: string) {
    return this.http.get<any>(`${this._baseUrl}/guides/${filter}`);
  }
  getScript(filter: string) {
    return this.http.get<any>(`${this._baseUrl}/script?file=${filter}`);
  }

  filterProducts(filter: string, origin: string) {
    return this.http.get<any>(`${this._baseUrl}/filter-products?filter=${filter}&origin=${origin}`);
  }

  getReport(groupId: string, isOrderComplete: boolean, data: any) {
    return this.http.post<any>(`${this._baseUrl}/orders-report/${groupId}/${isOrderComplete}`, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
  }

  getExportReport(groupId: string, isOrderComplete: boolean, data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    //@ts-ignore
    return this.http.post<any>(`${this._baseUrl}/export-orders-report/${groupId}/${isOrderComplete}`, JSON.stringify(data), { headers: headers, responseType: 'blob' });
  }

  getShippingData(productId: any) {
    return this.http.get<any>(`${this._baseUrl}/uba-shipping-details/${productId}`);
  }

  updateProduct(id: string, product: any) {
    return this.http.put<any>(`${this._baseUrl}/update-product/${id}`, JSON.stringify(product), { headers: { 'Content-Type': 'application/json' } });
  }

  startSVDWorkflow(input: any): Observable<any> {
    return this.http.post<{ executionArn: string }>(`${this._stepsUrl}/submit-svd-order`, input).pipe(
      switchMap((response: any) => {
        const arnUrl = this.cleanArnForUrl(response.executionArn);
        return this.pollExecutionStatus(arnUrl);
      })
    );
  }

  private cleanArnForUrl(arn: string) {
    return arn.replace(/:/g, '%3A').replace(/\$/g, '%24');
  };

  private pollExecutionStatus(executionArn: string): Observable<any> {
    const encodedArn = encodeURIComponent(executionArn);

    return new Observable(observer => {
      const checkStatus = async () => {
        try {
          const response = await this.http.get(`${this._stepsExecUrl}/check-execution/${encodedArn}`).toPromise();
            //@ts-ignore
          if (response.status === 'RUNNING') {
            // Wait 2 seconds before next poll
            setTimeout(checkStatus, 1000);
          } else {
            //@ts-ignore
            observer.next(response?.output);
            observer.complete();
          }
        } catch (error) {
          observer.error(error);
        }
      };

      // Start polling
      checkStatus();

      // Cleanup function
      return () => {
        // Any cleanup if needed
      };
    });
  }
}
